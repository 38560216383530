import { ref } from 'vue'
import { fetch } from '@/services/apiService'

export function useApiService(endpoint) {
  const data = ref(null)
  const error = ref(null)
  const loading = ref(true)

  async function fetchData(url) {
    // restore initial values
    loading.value = true
    error.value = null

    try {
      data.value = await fetch(url)
    } catch (err) {
      error.value = err.response.data
    } finally {
      loading.value = false
    }
  }

  if (endpoint) {
    fetchData(endpoint)
  }

  return { data, error, loading, fetchData }
}
