<script setup>
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt.vue'
import { useSidebarStore } from '@/stores/sidebar'
import { useUserProfileStore } from '@/stores/userProfile'

const sidebarStore = useSidebarStore()
const userProfileStore = useUserProfileStore()
</script>
<template>
  <CHeader position="sticky">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="sidebarStore.toggleSidebar()">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-lg-none" to="/">LOXEA</CHeaderBrand>
      <span>{{ userProfileStore.profile.email }}</span>
      <CHeaderNav class="flex-column flex-sm-row">
        <CFormSelect v-model="$i18n.locale">
          <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">
            {{ locale }}
          </option>
        </CFormSelect>
        <CNavItem>
          <CNavLink href="#">
            <CIcon class="mx-2" icon="cil-bell" size="lg" />
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink href="#">
            <CIcon class="mx-2" icon="cil-envelope-open" size="lg" />
          </CNavLink>
        </CNavItem>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
  </CHeader>
</template>

