// services/apiService.js
import axios from 'axios'
import { getAuthHeader } from '@/services/appService'

const apiEndpoint = import.meta.env.VITE_APP_BACKEND_URL

export async function fetch(url) {
  const response = await axios.get(`${apiEndpoint}/${url}`, {
    headers: getAuthHeader(),
  })
  return response.data
}
