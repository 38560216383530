export default {
  title: {
    help: 'Aide',
    lost: 'Oups ! Vous êtes perdu !',
    dashboard: 'Dashboard',
    iaf: 'Investment Approval Form',
    rs: 'Feuille de rentabilité',
    newrs: "Creation d'une feuille de rentabilité",
    editrs: "Edition d'une feuille de rentabilite",
    vehicles: 'Vehicules',
    workflows: 'Validations',
  },
  routes: {
    home: 'Accueil',
    dashboard: 'Tableau de bord',
    settings: 'Configuration',
    appSettings: 'Configuration générale',
    users: 'Utilisateurs',
    user: 'Utilisateur',
    myProfile: 'Configuration',
    workflows: 'Flux de validation',
    workflowsList: 'Liste',
    workflowsView: 'Visualisation',
    offers: 'Offres',
    offersList: 'Liste',
    offerView: 'Visualisation',
    offerCreation: 'Creation',
    rs: 'Feuille de rentabilité',
    rsList: 'Feuilles actives',
    rsCreation: 'Creation',
    rsEdition: 'Edition',
    iar: 'Demande authorisation investissement',
    iarList: 'Liste',
    iarCreation: 'Creation',
    vehicles: 'Vehicules',
  },
  navMenu: {
    offers: 'Offres',
    rs: 'RS',
    workflows: 'Flux de validations',
    settings: 'Paramètres',
    vehicles: 'Vehicules',
  },
  message: {
    welcome: 'Application Loxea',
    login: 'Connectez-vous à votre compte',
    hello: 'Bonjour, world',
    forgotPassword: 'Mot de passe oublié',
    loginHelp: "Vous n'avez pas encore d'accès ? Demandez le",
    lost: "La page que vous recherchez n'existe pas.",
    error: 'There is an error with your request',
    loginError: "Nom d'utilisateur ou mot de passe incorrect",
  },
  input: {
    username: 'Email',
    password: 'Mot de passe',
    login: 'Connexion',
    accessRequest: 'Demander un accès',
    comment: 'Commentaire',
    description: 'Description',
    feature: 'Fonctionnalité',
    amount: 'Montant',
  },
  iaf: {
    subsidiary: 'Filiale',
    region: 'Region',
    division: 'Division',
    iaf: 'DAI',
    iafFull: "Demande d'autorisation d'investissement",
    entity: 'Entité',
    items: 'Elements',
  },
  rentability: {
    labels: {
      assumptions: 'Hypothèses',
      misc: 'Divers',
      disposal: 'Cession',
      operations: 'Opérations',
      synthesis: 'Synthèse',
    },
    sections: {
      contractAssumptions: 'Hypothèses du contrat',
      adminAndOthers: 'Admin et autres',
      disposal: 'Disposal',
      financing: 'Financement',
      maintenance: 'Maintenance',
      insurance: 'Assurance',
      carRelief: 'Vehicule remplacement',
      carReliefDisaster: 'Vehicule remplacement sinistre',
      driverServices: 'Prestation chauffeur',
      contractManagement: 'Gestion contrat',
      keyElements: 'Eléments clés de rentabilité du contrat',
      purchasePriceComission: "Commission sur prix d'achat",
      vehicleDisposal: 'Cession du véhicule',
      fundingValues: 'Valeurs de financement',
      reliefCarBreakdown: 'Véhicule de remplacement - Panne mécanique',
      reliefCarDamage: 'Véhicule de remplacement - Sinistre',
      assistance: 'Assistance',
      tracking: 'Localisation',
      technicalInspection: 'Contrôle technique',
      fileMargin: 'marge dossier',
      avisFees: 'Charges Avis',
      monthlyOperation: 'Exploitation mensuelle',
      contractOperation: 'Exploitation contract',
      disposalContractDuration: 'Cession durée contrat',
      totalContract: 'Total',
    },
    params: {
      brand: 'Marque',
      model: 'Modèle',
      version: 'Version',
      biPartiteLease: 'Bi partite - Crédit Bail',
      discountMargin: 'Remise / Marge',
      rate: 'Taux (%)',
      cityAssignment: "Ville d'affectation du vehicule",
      optionsAccessories: 'Options and accessories integrated into the PA',
      transactionPriceToBank: 'Transaction price to the bank',
      additionalAccessories: 'Accessoires supplémentaires non immobilisés',
      numberOfVehicules: 'Nombre de vehicules',
      capex: 'Capex',
      contractDuration: 'Durée du contrat',
      contractedHours: 'Heures contractuelles',
      contractedKm: 'Km au contrat',
      vehicleDepreciationPeriod: "Durée d'amortissement du véhicule",
      buypackPA: 'Buypack %PA',
      buypackPS: 'Buypack %PS',
      calcBankRepurchase: 'Repurchase commitment to the bank - %PA hold',
      manualBankRepurchase: 'Repurchase commitment to the bank - %PA manual',
      monthlyRentalPrice: 'Loyer mensuel',
      marginTotal: 'Marge totale',
      disposalMargin: 'Marge de cession',
      standardPrice: 'Prix standard',
      discountPrice: 'Remise prix',
      purchasePrice: "Prix d'achat",
      netBookValue: 'Valeur nette',
      extraCostPricePurchase: 'Cout supplementaires',
      residualValueInRentCalculation: 'Valeur résiduelle dans calcul loyer',
      repurchaseCommitmentToTheBank: 'Engagement de rachat envers la banque',
      financingCost: 'Coût du financement',
      markUp: 'Mark up',
      maintenance: 'Entretien',
      consoTiresBatteries: 'Conso pneu / batterie',
      securityMaintenanceCosts: 'Securité couts de maintenance',
      maintenanceCosts: 'Maintenance couts retenus',
      reliefCar: 'Vehicule de remplacement',
      tracking: 'Tracking',
      trackingDevice: 'Boitier tracking',
      trackingSubscription: 'Abo Tracking',
      telcoSubscription: 'Abo Telco',
      otherSubscription: 'Abo Other',
      technicalInspection: 'Controle technique',
      vignette: 'Vignette',
      parking: 'Parking',
      legalTaxDocument: 'Impot sur acte juridique',
      miscAdminCosts: 'Frais administratifs divers',
      disposalValue: 'Valeur de cession',
      additionalReconditionningCosts: 'Frais de remise en état complémentaires',
      resaleFinancingCustomer: 'Revente au client',
      monthlyTurnover: "Chiffre d'affaire mensuel",
      monthlyCost: 'Cout mensuel',
      debtFinancing: 'Financement de la dete',
      grossMonthlyMargin: 'Marge brute mensuelle',
      excludingFinancialExpenses: 'hors frais financier',
      netMonthlyMargin: 'Marge nette mensuelle',
      afterFinancialExpenses: 'Apres frais financiers',
      loxeaKmCost: 'Cout par KM Loxea',
      customerKmCost: 'Cout par Km client',
      costOverContractLife: 'Cout pour la duree du contrat',
      insurrance: 'Assurance',
      annualCost: 'Couts annuel',
      breakdownCoverageRatio: 'Ratio de couverture (flotte remplacement / flotte client)',
      driverServices: 'Prestation chauffeur',
      contractManagement: 'Gestion contrat',
      fileMargin: 'Marge dossier',
      financialTurnover: "Chiffre d'affaires financement",
      monthlyCostExcludingFinancialCosts: 'Coût mensuel hors frais financiers',
      monthlyCostWithFinancialExpenses: 'Coût mensuel avec frais financiers',
      servicesTurnover: "Chiffre d'affaires Services",
      monthlyCostOfServices: 'Coût mensuel Services',
      monthlyContributionPurchasingCommission: 'Contribution mensuelle Commission Achat',
      montlhyMargin: 'Marge mensuelle',
      costsOfNonCapitalizedAccessories: 'Coût des accessoires non immobilisés',
      avisFeesMonthly: 'Redevance Avis (mensuelle)',
      fileMarginMonthly: 'Marge dossier (mensuelle)',
      disposalCost: 'Cout de session',
      turnover: "Chiffre d'affaire",
      costExcludingFinancialExpenses: 'Coût hors frais financiers',
      totalGrossMargin: 'marge totale brute',
      costsIncludingFinancialExpenses: 'Coût avec frais financiers',
      totalNetMargin: 'marge totale nette',
      grossOperatingMargin: "Marge d'exploitation brute",
      netOperatingMargin: "Marge d'exploitation nette",
    },
  },
  offer: {
    title: 'Liste des offres',
    open: "Voir le detail de l'offre",
    new: 'Creer une',
    draft: 'Brouillon',
    submitted: 'Soumis a validation',
    validated: 'Validée',
    invalidated: 'Invalidée',
    cancelled: 'Annulée',
    archived: 'Archivée',
    accepted: 'Acceptée',
    declined: 'Refusée',
    baseOffer: 'Offre de base',
    optionDesc: "Description de l'option",
    nextValidation: 'Prochaine validation',
    allRequired: 'Toutes les validations sont requises',
    someRequired: 'Une seule validation est requise',
    editWarningTitle: 'Attention, vous allez modifier une offre',
    editWarningMsg: 'La version actuelle (v{actualVersion}) sera archivée et remplacée par une nouvelle',
    addDescription: 'Ajouter une description',
    clientOfferSelectionTitle: "Selectionnez l'option retenue par le client",
    clientValidation: 'Offre acceptée par le client',
    optionDescTitle: 'Ajouter / mettre a jour la description',
    optionDescription: "Description de l'option",
  },
  misc: {
    create: 'Creer',
    loading: 'Chargement...',
    select: 'Selection...',
    error: 'Une erreur est survenue lors du chargement',
    status: 'Statut',
    country: 'Pays',
    org: 'Organization',
    creationDate: 'Date de creation',
    validationDate: 'Date de validation',
    amount: 'Montant',
    description: 'Description',
    client: 'Client',
    continue: 'Continuer',
    cancel: 'Annuler',
    currency: 'Devise',
    organization: 'Organisation',
    required: 'Obligatoire',
    delete: 'Supprimer',
    duplicate: 'Dupliquer',
  },
  actions: {
    submit: 'Soumettre',
    edit: 'Modifier',
    validation: 'Valider',
    cancel: 'Annuler',
    unvalidation: 'Invalider',
    ack: 'Ack',
    save: 'Sauvegarder',
  },
  features: {
    Offer_LLD_CAR: 'offre LLD voiture',
    error: "Cette fonctionalité n'est pas disponible",
  },
}
