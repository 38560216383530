<script setup>
import axios from 'axios'
import { inject } from 'vue';
import { getAuthHeader, getData, clearProfile } from '@/services/appService'
import { useUserProfileStore } from '@/stores/userProfile'
import router from '@/router'
import { CButton } from '@coreui/vue'

const apiUrl = inject('apiUrl')

const userProfileStore = useUserProfileStore()

/**
 * Logout method.
 * The token is send to beinvalidate.
 * It will be a better approach to send an id (user)
 * and invalidate the associate token
 */
const logout = () => {
  const token = getData('token')
  if (token) {
    axios
      .get(`${apiUrl}/token/invalidate/${token}`, {
        headers: getAuthHeader(),
      })
      .then(() => {
        // Delete token key
        clearProfile()
        // Clear store values
        userProfileStore.clearProfile()
        // redirect to login
        router.push('/login')
      })
      .catch((error) => console.log(error))
  }
}
</script>
<template>
  <CDropdown>
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <CAvatar size="md" color="primary" text-color="white">{{ userProfileStore.profile.initials }}</CAvatar>
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2"> Account </CDropdownHeader>
      <CDropdownItem href="#/user/me"> <CIcon icon="cil-user" /> Settings </CDropdownItem>
      <CDropdownDivider />
      <CDropdownItem>
        <CButton @click="logout()"> <CIcon icon="cil-lock-locked" /> Logout </CButton>
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

